const listCustomIcons = [
    {
        'i-cmsv1': `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        width="19px" height="19px" viewBox="0 0 19 19" enable-background="new 0 0 19 19" xml:space="preserve">
        <path fill="#000" d="M10.288,8.123c-2-0.804-2.58-1.29-2.58-2.174c0-0.707,0.533-1.528,2.026-1.528
        c1.324,0,2.34,0.527,2.351,0.532c0.106,0.057,0.226,0.087,0.343,0.087c0.286,0,0.544-0.18,0.652-0.454l0.363-0.934
        c0.128-0.341-0.066-0.635-0.309-0.745c-0.815-0.356-2.411-0.629-2.427-0.636c-0.025-0.004-0.112-0.025-0.112-0.129l-0.005-1.357
        c0-0.409-0.339-0.739-0.754-0.739H9.181c-0.411,0-0.755,0.33-0.755,0.739l0.006,1.43c0,0.109-0.118,0.155-0.165,0.166
    C6.261,2.861,5.004,4.343,5.004,6.213c0,2.33,1.913,3.383,3.98,4.174c1.653,0.655,2.325,1.316,2.325,2.287
    c0,1.059-0.954,1.797-2.325,1.797c-1.163,0-2.744-0.748-2.76-0.753c-0.103-0.053-0.21-0.074-0.318-0.074
        c-0.302,0-0.564,0.183-0.667,0.467l-0.35,0.944c-0.123,0.358,0.067,0.642,0.308,0.77c0.976,0.507,2.853,0.805,2.935,0.821
    c0.02,0,0.139,0.041,0.139,0.145v1.42c0,0.416,0.339,0.744,0.754,0.744h0.672c0.415,0,0.754-0.328,0.754-0.744v-1.49
        c0-0.14,0.103-0.151,0.124-0.161c2.145-0.481,3.463-2.056,3.463-4.039C14.039,10.484,12.916,9.172,10.288,8.123z"/>
    </svg>
`,
    },

    {
        'i-clock': `<svg class="article-clock" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 1000 1000"><path d="M500.21 8.14C228.467 8.14 8.14 228.444 8.14 500.21c0 271.792 220.326 492.07 492.07 492.07 271.742 0 492.07-220.278 492.07-492.07 0-271.767-220.328-492.07-492.07-492.07zm190.257 791.18l-251.97-251.97V140.37h80v373.845L747.036 742.75l-56.568 56.57z"/></svg>
`,
    },

    {
        'i-additional-infos': `
<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 145.7 125.6" style="enable-background:new 0 0 145.7 125.6;" xml:space="preserve">
<style type="text/css">
	.st0{fill:#E62700;}
</style>
<g>

		<rect x="57.7" y="48.2" transform="matrix(6.123234e-17 -1 1 6.123234e-17 28.683 136.1006)" class="st0" width="49.4" height="11.1"/>

		<rect x="76.8" y="86.8" transform="matrix(6.123234e-17 -1 1 6.123234e-17 -9.9147 174.6982)" class="st0" width="11.1" height="11.1"/>
</g>
<path class="st0" d="M82.4,125.6c-16.1,0-32.2-6.1-44.4-18.4c-7.5-7.5-13-16.8-15.9-27c-1.1-0.5-2.7-1.2-4.2-1.8
	C3.7,72.3,0.1,70.2,0,66.4c-0.1-3.5,2.7-5.4,15.7-13c1.8-1,3.9-2.2,5.1-3c2.4-12.2,8.3-23.2,17.2-32.1c24.5-24.5,64.4-24.5,88.9,0
	c11.9,11.9,18.4,27.6,18.4,44.4c0,16.8-6.5,32.6-18.4,44.4l0,0l0,0C114.6,119.5,98.5,125.6,82.4,125.6z M10.2,66.1
	c3.3,1.7,8.3,3.9,10.8,5c6.8,3,7.7,3.4,8.3,5.5c2.4,9.4,7.4,18.1,14.3,25c21.4,21.4,56.2,21.4,77.5,0l0,0
	c10.4-10.4,16.1-24.1,16.1-38.8c0-14.6-5.7-28.4-16.1-38.8C99.8,2.7,65,2.7,43.6,24c-8,8-13.3,18.2-15.2,29.3
	c-0.4,2.2-1.2,2.7-8.7,7C17.3,61.8,13.1,64.2,10.2,66.1z"/>
</svg>`,
    },
    {
        'i-header': `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="19px" height="19px" viewBox="0 0 19 19" enable-background="new 0 0 19 19" xml:space="preserve">
<g>
	<rect x="1" y="7.928" fill="none" width="5.063" height="10.072"/>
	<rect x="7.125" y="7.928" fill="none" width="10.875" height="10.072"/>
	<path fill="#FFFFFF" d="M7.125,6.928H6.063H0V19h6.063h1.063H19V6.928H7.125z M1,18V7.928h5.063V18H1z M18,18H7.125V7.928H18V18z"/>
</g>
<rect fill="#FFFFFF" width="19" height="5.248"/>
</svg>
`,
    },
    {
        'i-logo-live': `<svg width="22" height="24" viewBox="0 0 22 20" xmlns="http://www.w3.org/2000/svg">
<path d="M20 4h-7.59L15.7.71 15 0l-4 4-4-4-.71.71L9.59 4H2C.9 4 0 4.89 0 6v12c0 1.1.9 2 2 2h18c1.1 0 2-.9 2-2V6c0-1.11-.9-2-2-2zm0 14H2V6h18v12zM8 8v8l7-4-7-4z" fill="#fff" fill-rule="evenodd"/>
</svg>
`,
    },
    {
        'i-quote-left': `<svg width="1792" height="1792" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M832 960v384q0 80-56 136t-136 56h-384q-80 0-136-56t-56-136v-704q0-104 40.5-198.5t109.5-163.5 163.5-109.5 198.5-40.5h64q26 0 45 19t19 45v128q0 26-19 45t-45 19h-64q-106 0-181 75t-75 181v32q0 40 28 68t68 28h224q80 0 136 56t56 136zm896 0v384q0 80-56 136t-136 56h-384q-80 0-136-56t-56-136v-704q0-104 40.5-198.5t109.5-163.5 163.5-109.5 198.5-40.5h64q26 0 45 19t19 45v128q0 26-19 45t-45 19h-64q-106 0-181 75t-75 181v32q0 40 28 68t68 28h224q80 0 136 56t56 136z"/></svg>

`,
    },
    {
        'i-quote-right': `<svg width="1792" height="1792" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M832 320v704q0 104-40.5 198.5t-109.5 163.5-163.5 109.5-198.5 40.5h-64q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h64q106 0 181-75t75-181v-32q0-40-28-68t-68-28h-224q-80 0-136-56t-56-136v-384q0-80 56-136t136-56h384q80 0 136 56t56 136zm896 0v704q0 104-40.5 198.5t-109.5 163.5-163.5 109.5-198.5 40.5h-64q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h64q106 0 181-75t75-181v-32q0-40-28-68t-68-28h-224q-80 0-136-56t-56-136v-384q0-80 56-136t136-56h384q80 0 136 56t56 136z"/></svg>

`,
    },

    {
        'i-logo-facebook': `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="16px" height="16px" viewBox="0 0 16 16" style="enable-background:new 0 0 16 16;" xml:space="preserve">
<path class="st0" d="M12.1,0.1v2.5h-1.5c-0.6,0-0.9,0.1-1.1,0.3C9.3,3.2,9.2,3.6,9.2,4v1.8H12l-0.4,2.8H9.2V16H6.3V8.7H3.9V5.9h2.4
	V3.8c0-1.2,0.3-2.1,1-2.8S8.9,0,10,0C10.9,0,11.6,0.1,12.1,0.1z"/>
</svg>

`,
    },

    {
        'i-logo-twitter': `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="16px" height="16px" viewBox="0 0 16 16" style="enable-background:new 0 0 16 16;" xml:space="preserve">
<path class="st0" d="M16,3.1c-0.5,0.7-1,1.2-1.6,1.7c0,0.1,0,0.2,0,0.4c0,0.9-0.1,1.7-0.4,2.6s-0.6,1.7-1.2,2.5
	c-0.5,0.8-1.1,1.5-1.9,2.1c-0.7,0.6-1.6,1.1-2.6,1.5c-1,0.4-2.1,0.5-3.3,0.5c-1.8,0-3.5-0.5-5-1.5c0.2,0,0.5,0,0.8,0
	c1.5,0,2.9-0.5,4-1.4c-0.7,0-1.3-0.2-1.9-0.7C2.4,10.6,2,10,1.8,9.4c0.2,0,0.4,0.1,0.6,0.1c0.3,0,0.6,0,0.9-0.1
	C2.6,9.2,1.9,8.8,1.4,8.2S0.7,6.9,0.7,6.1v0c0.5,0.3,0.9,0.4,1.5,0.4C1.7,6.2,1.4,5.8,1.1,5.3s-0.4-1-0.4-1.6c0-0.6,0.1-1.1,0.4-1.6
	c0.8,1,1.8,1.8,3,2.4s2.4,0.9,3.7,1C7.8,5.3,7.8,5,7.8,4.8c0-0.9,0.3-1.7,1-2.3s1.4-1,2.3-1c0.9,0,1.7,0.3,2.4,1
	c0.7-0.1,1.4-0.4,2.1-0.8c-0.2,0.8-0.7,1.4-1.4,1.8C14.7,3.5,15.3,3.3,16,3.1z"/>
</svg>
`,
    },

    {
        'i-logo-googleplus': `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="16px" height="16px" viewBox="0 0 16 16" style="enable-background:new 0 0 16 16;" xml:space="preserve">
<path class="st0" d="M8.2,6.9h7.3c0.1,0.5,0.1,0.9,0.1,1.3c0,1.5-0.3,2.8-0.9,3.9s-1.5,2-2.6,2.7s-2.4,1-3.9,1c-1.1,0-2.1-0.2-3-0.6
	s-1.8-1-2.5-1.6C2,12.8,1.4,12,1,11s-0.6-2-0.6-3S0.6,5.9,1,5s1-1.8,1.6-2.5s1.5-1.2,2.5-1.6s2-0.6,3-0.6c2,0,3.8,0.7,5.2,2l-2.1,2
	c-0.8-0.8-1.9-1.2-3.1-1.2c-0.9,0-1.7,0.2-2.4,0.7C5,4.2,4.4,4.8,4,5.5C3.6,6.3,3.4,7.1,3.4,8S3.6,9.7,4,10.5c0.4,0.8,1,1.3,1.8,1.8
	s1.5,0.7,2.4,0.7c0.6,0,1.1-0.1,1.6-0.2c0.5-0.2,0.9-0.4,1.2-0.6s0.6-0.5,0.8-0.8c0.2-0.3,0.4-0.6,0.5-0.9c0.1-0.3,0.2-0.5,0.2-0.8
	H8.2V6.9z"/>
</svg>

`,
    },

    {
        'i-sandbox': `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="19px" height="19px" viewBox="0 0 19 19" enable-background="new 0 0 19 19" xml:space="preserve">
<g>
	<path fill="none" d="M8.08,11.209h2.841c0.344,0,0.623-0.279,0.623-0.625s-0.279-0.626-0.623-0.626H8.08
		c-0.345,0-0.625,0.28-0.625,0.626S7.735,11.209,8.08,11.209z"/>
	<path fill="none" d="M6.97,4.632h5.06V3.938c0-0.347-0.283-0.63-0.629-0.63H7.6c-0.348,0-0.63,0.283-0.63,0.63V4.632z"/>
	<path fill="none" d="M18.008,7.17V6.063c0-0.242-0.195-0.438-0.437-0.438H1.429c-0.242,0-0.438,0.196-0.438,0.438v1.073
		l5.638,2.735C6.899,9.325,7.466,8.966,8.08,8.966h2.841c0.612,0,1.181,0.359,1.451,0.905L18.008,7.17z"/>
	<path fill="none" d="M1.429,15.693h16.143c0.232,0,0.424-0.182,0.437-0.412V8.272l-5.505,2.64c-0.152,0.74-0.82,1.291-1.582,1.291
		H8.08c-0.774,0-1.43-0.539-1.584-1.291L0.99,8.24v7.016C0.99,15.496,1.187,15.693,1.429,15.693z"/>
	<path fill="#FFFFFF" d="M17.571,4.632h-4.548V3.938c0-0.895-0.729-1.623-1.623-1.623H7.6c-0.895,0-1.623,0.728-1.623,1.623v0.694
		H1.429c-0.789,0-1.431,0.642-1.431,1.431v9.193c0,0.789,0.642,1.43,1.431,1.43h16.143c0.788,0,1.431-0.641,1.431-1.43V6.063
		C19.002,5.274,18.359,4.632,17.571,4.632z M7.6,3.308h3.8c0.346,0,0.629,0.283,0.629,0.63v0.694H6.97V3.938
		C6.97,3.591,7.252,3.308,7.6,3.308z M8.08,9.958h2.841c0.344,0,0.623,0.28,0.623,0.626s-0.279,0.625-0.623,0.625H8.08
		c-0.345,0-0.625-0.279-0.625-0.625S7.735,9.958,8.08,9.958z M10.921,8.966H8.08c-0.614,0-1.181,0.359-1.452,0.905L0.99,7.136V6.063
		c0-0.242,0.197-0.438,0.438-0.438h16.143c0.241,0,0.437,0.196,0.437,0.438V7.17l-5.636,2.701
		C12.102,9.325,11.533,8.966,10.921,8.966z M0.99,8.24l5.505,2.672c0.154,0.752,0.81,1.291,1.584,1.291h2.841
		c0.762,0,1.43-0.551,1.582-1.291l5.505-2.64v7.009c-0.013,0.23-0.204,0.412-0.437,0.412H1.429c-0.242,0-0.438-0.197-0.438-0.438
		V8.24z"/>
</g>
</svg>
`,
    },
    {
        'i-translate': `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="19px" height="19px" viewBox="0 0 19 19" enable-background="new 0 0 19 19" xml:space="preserve">
<g>
	<path fill="none" d="M17.663,6.762h-6.11v3.507c0,0.683-0.588,1.236-1.312,1.236l-0.753-0.028V10.39l0.753,0.028
		c0.129,0,0.225-0.079,0.225-0.149V6.762V5.674v-2.72c0-0.07-0.096-0.148-0.225-0.148H1.313c-0.128,0-0.225,0.079-0.225,0.148v7.314
		c0,0.07,0.097,0.149,0.225,0.149h1.398l0.085,0.439c0.079,0.41,0.192,0.836,0.294,1.132c0.615-0.548,1.62-1.437,1.62-1.437
		l0.154-0.135h2.559V6.91c0-0.682,0.588-1.236,1.313-1.236h0.753v1.087H8.735C8.606,6.762,8.51,6.84,8.51,6.91v3.508v1.087v2.72
		c0,0.069,0.097,0.148,0.225,0.148h5.377l0.153,0.136c0,0,1.006,0.889,1.621,1.437c0.102-0.296,0.215-0.723,0.294-1.132l0.085-0.44
		h1.397c0.128,0,0.225-0.079,0.225-0.148V6.91C17.888,6.84,17.791,6.762,17.663,6.762z"/>
	<path fill="#FFFFFF" d="M17.663,5.674h-6.11v-2.72c0-0.682-0.588-1.236-1.312-1.236H1.313C0.588,1.719,0,2.273,0,2.955v7.314
		c0,0.683,0.588,1.236,1.313,1.236H1.82c0.415,1.813,0.887,1.821,1.151,1.821c0.001,0,0.002,0,0.003,0l0.301-0.041l0.155-0.141
		c0.303-0.275,1.43-1.273,1.846-1.64h2.147v2.72c0,0.682,0.588,1.235,1.313,1.235H13.7c0.415,0.366,1.543,1.364,1.846,1.64
		l0.245,0.182h0.21c0.001,0,0.002,0,0.003,0c0.265,0,0.737-0.008,1.152-1.821h0.507c0.724,0,1.313-0.554,1.313-1.235V6.91
		C18.976,6.229,18.387,5.674,17.663,5.674z M17.888,14.225c0,0.069-0.097,0.148-0.225,0.148h-1.397l-0.085,0.44
		c-0.079,0.409-0.192,0.836-0.294,1.132c-0.615-0.548-1.621-1.437-1.621-1.437l-0.153-0.136H8.735c-0.128,0-0.225-0.079-0.225-0.148
		v-2.72v-1.087V6.91c0-0.07,0.097-0.148,0.225-0.148h0.753V5.674H8.735c-0.724,0-1.313,0.554-1.313,1.236v3.508H4.863l-0.154,0.135
		c0,0-1.005,0.889-1.62,1.437c-0.102-0.296-0.215-0.722-0.294-1.132l-0.085-0.439H1.313c-0.128,0-0.225-0.079-0.225-0.149V2.955
		c0-0.07,0.097-0.148,0.225-0.148h8.929c0.129,0,0.225,0.079,0.225,0.148v2.72v1.087v3.507c0,0.07-0.096,0.149-0.225,0.149
		L9.488,10.39v1.087l0.753,0.028c0.724,0,1.312-0.554,1.312-1.236V6.762h6.11c0.128,0,0.225,0.078,0.225,0.148V14.225z"/>
</g>
<g>
	<path fill="#FFFFFF" d="M6.463,8.977H5.588L5.24,8.071H3.646L3.318,8.977H2.464l1.553-3.985h0.851L6.463,8.977z M4.982,7.399
		L4.432,5.92L3.894,7.399H4.982z"/>
</g>
<g>
	<path fill="#FFFFFF" d="M17.087,12.008l-1.9-1.479c-0.014-0.01-0.023-0.027-0.023-0.046v-0.138c0-0.021,0.013-0.043,0.034-0.053
		l1.031-0.533c0.129-0.069,0.272-0.103,0.416-0.106c0.114-0.003,0.211-0.086,0.23-0.198l0.01-0.057
		c0.01-0.047,0.001-0.098-0.019-0.142l-0.084-0.178c-0.039-0.082-0.12-0.135-0.211-0.136L16.41,8.937
		c-0.132-0.002-0.24,0.101-0.244,0.232l0,0c-0.003,0.082-0.047,0.157-0.118,0.198l-0.995,0.584c-0.04,0.024-0.091-0.004-0.091-0.052
		V8.393c0-0.012-0.004-0.023-0.011-0.033l-0.141-0.206c-0.076-0.111-0.223-0.144-0.339-0.077l-0.249,0.14
		c-0.034,0.02-0.042,0.067-0.014,0.095l0.226,0.234c0.011,0.012,0.018,0.025,0.018,0.042v1.248c0,0.05-0.06,0.08-0.101,0.046
		L14.25,9.797c-0.062-0.051-0.144-0.064-0.217-0.035l-1.503,0.593c-0.145,0.058-0.203,0.229-0.124,0.362l0.111,0.188
		c0.075,0.125,0.22,0.188,0.358,0.162c0.026-0.004,0.046-0.024,0.049-0.051l0.025-0.164c0.003-0.02,0.018-0.037,0.036-0.045
		l0.996-0.414c0.058-0.025,0.109,0.047,0.068,0.096l-1.021,1.196c-0.17,0.199-0.374,0.365-0.602,0.492
		c-0.053,0.029-0.034,0.11,0.027,0.113l0,0c0.509,0.021,0.993-0.224,1.274-0.647l0.612-0.918c0.031-0.049,0.11-0.025,0.11,0.033
		v1.521c0,0.032-0.028,0.061-0.062,0.061h-0.495c-0.051,0-0.079,0.056-0.049,0.096l0.745,1c0.025,0.033,0.075,0.031,0.098-0.001
		c0.178-0.265,0.272-0.574,0.272-0.894v-1.584c0-0.055,0.069-0.082,0.105-0.038l1.118,1.322c0.015,0.016,0.035,0.024,0.058,0.02
		l0.818-0.147C17.113,12.106,17.129,12.04,17.087,12.008z"/>
</g>
</svg>
`,
    },
    {
        'i-wires': `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="19px" height="19px" viewBox="0 0 19 19" enable-background="new 0 0 19 19" xml:space="preserve">
<polygon fill="#FFFFFF" points="6.878,13.566 6.878,10.814 6.878,9.635 13.039,9.635 14.088,9.635 14.104,9.635 14.104,8.586
	6.878,8.586 6.878,3.802 5.83,3.802 5.83,8.586 5.83,8.698 5.83,9.635 5.83,10.814 5.83,13.566 5.83,14.418 5.83,15.598
	5.83,15.711 5.83,16.646 6.878,16.646 13.039,16.646 14.088,16.646 14.104,16.646 14.104,15.598 6.878,15.598 6.878,14.418 "/>
<circle fill="#FFFFFF" cx="6.354" cy="2.753" r="2.753"/>
<circle fill="#FFFFFF" cx="13.563" cy="9.11" r="2.752"/>
<circle fill="#FFFFFF" cx="13.563" cy="16.123" r="2.752"/>
</svg>
`,
    },
    {
        'i-text-justify': `
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="16px" height="16px" viewBox="0 0 16 16" style="enable-background:new 0 0 16 16;" xml:space="preserve">
<g>
	<path class="st0" d="M14.8,22.4H5.4c-0.6,0-1.1-0.5-1.1-1.1v-0.1c0-0.6,0.5-1.1,1.1-1.1h9.5c0.6,0,1.1,0.5,1.1,1.1v0.1
		C15.9,21.9,15.4,22.4,14.8,22.4z"/>
	<path class="st0" d="M14.8,31.3H4.5c-0.6,0-1.1-0.5-1.1-1.1v-0.1c0-0.6,0.5-1.1,1.1-1.1h10.3c0.6,0,1.1,0.5,1.1,1.1v0.1
		C15.9,30.8,15.4,31.3,14.8,31.3z"/>
	<path class="st0" d="M14.8,26.9H2c-0.6,0-1.1-0.5-1.1-1.1v-0.1c0-0.6,0.5-1.1,1.1-1.1h12.9c0.6,0,1.1,0.5,1.1,1.1v0.1
		C15.9,26.4,15.4,26.9,14.8,26.9z"/>
	<path class="st0" d="M14.8,35.7H1.1c-0.6,0-1.1-0.5-1.1-1.1v-0.1c0-0.6,0.5-1.1,1.1-1.1h13.7c0.6,0,1.1,0.5,1.1,1.1v0.1
		C15.9,35.2,15.4,35.7,14.8,35.7z"/>
	<path class="st0" d="M15,3H1C0.4,3,0,2.6,0,2v0c0-0.5,0.4-1,1-1h14c0.5,0,1,0.4,1,1v0C16,2.6,15.6,3,15,3z"/>
	<path class="st0" d="M15,7H1C0.4,7,0,6.6,0,6v0c0-0.5,0.4-1,1-1h14c0.5,0,1,0.4,1,1v0C16,6.6,15.6,7,15,7z"/>
	<path class="st0" d="M15,15H1c-0.5,0-1-0.4-1-1v0c0-0.5,0.4-1,1-1h14c0.5,0,1,0.4,1,1v0C16,14.6,15.6,15,15,15z"/>
	<path class="st0" d="M15,11H1c-0.5,0-1-0.4-1-1v0c0-0.5,0.4-1,1-1h14c0.5,0,1,0.4,1,1v0C16,10.6,15.6,11,15,11z"/>
</g>
<path class="st0" d="M-9.2,10.8h-13.7c-0.6,0-1.1-0.5-1.1-1.1V9.6c0-0.6,0.5-1.1,1.1-1.1h13.7c0.6,0,1.1,0.5,1.1,1.1v0.1
	C-8.1,10.3-8.6,10.8-9.2,10.8z"/>
<path class="st0" d="M-9.2,7.3h-13.7c-0.6,0-1.1-0.5-1.1-1.1V6.2c0-0.6,0.5-1.1,1.1-1.1h13.7c0.6,0,1.1,0.5,1.1,1.1v0.1
	C-8.1,6.8-8.6,7.3-9.2,7.3z"/>
<path class="st0" d="M-9.2,3.9h-13.7c-0.6,0-1.1-0.5-1.1-1.1V2.8c0-0.6,0.5-1.1,1.1-1.1h13.7c0.6,0,1.1,0.5,1.1,1.1v0.1
	C-8.1,3.4-8.6,3.9-9.2,3.9z"/>
<path class="st0" d="M-13.4,14.2h-9.5c-0.6,0-1.1-0.5-1.1-1.1V13c0-0.6,0.5-1.1,1.1-1.1h9.5c0.6,0,1.1,0.5,1.1,1.1v0.1
	C-12.3,13.7-12.8,14.2-13.4,14.2z"/>
</svg>
`,
    },
    {
        'i-text-left': `
<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="16px" height="16px" viewBox="0 0 16 16" style="enable-background:new 0 0 16 16;" xml:space="preserve">
<path class="st0" d="M11.6,3H1C0.4,3,0,2.6,0,2v0c0-0.5,0.4-1,1-1h10.6c0.6,0,1,0.4,1,1v0C12.6,2.6,12.1,3,11.6,3z"/>
<path class="st0" d="M14.1,7H1C0.4,7,0,6.6,0,6v0c0-0.5,0.4-1,1-1h13.1c0.6,0,1,0.4,1,1v0C15.1,6.6,14.7,7,14.1,7z"/>
<path class="st0" d="M15,15H1c-0.5,0-1-0.4-1-1v0c0-0.5,0.4-1,1-1h14c0.5,0,1,0.4,1,1v0C16,14.6,15.6,15,15,15z"/>
<path class="st0" d="M13,11H1c-0.5,0-1-0.4-1-1v0c0-0.5,0.4-1,1-1h12c0.5,0,1,0.4,1,1v0C14,10.6,13.6,11,13,11z"/>
<path class="st0" d="M-9.2,10.8h-13.7c-0.6,0-1.1-0.5-1.1-1.1V9.6c0-0.6,0.5-1.1,1.1-1.1h13.7c0.6,0,1.1,0.5,1.1,1.1v0.1
	C-8.1,10.3-8.6,10.8-9.2,10.8z"/>
<path class="st0" d="M-9.2,7.3h-13.7c-0.6,0-1.1-0.5-1.1-1.1V6.2c0-0.6,0.5-1.1,1.1-1.1h13.7c0.6,0,1.1,0.5,1.1,1.1v0.1
	C-8.1,6.8-8.6,7.3-9.2,7.3z"/>
<path class="st0" d="M-9.2,3.9h-13.7c-0.6,0-1.1-0.5-1.1-1.1V2.8c0-0.6,0.5-1.1,1.1-1.1h13.7c0.6,0,1.1,0.5,1.1,1.1v0.1
	C-8.1,3.4-8.6,3.9-9.2,3.9z"/>
<path class="st0" d="M-13.4,14.2h-9.5c-0.6,0-1.1-0.5-1.1-1.1V13c0-0.6,0.5-1.1,1.1-1.1h9.5c0.6,0,1.1,0.5,1.1,1.1v0.1
	C-12.3,13.7-12.8,14.2-13.4,14.2z"/>
</svg>

`,
    },

    {
        'i-text-right': `
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="16px" height="16px" viewBox="0 0 16 16" style="enable-background:new 0 0 16 16;" xml:space="preserve">
<path class="st0" d="M15,3H4.4c-0.5,0-1-0.4-1-1v0c0-0.5,0.4-1,1-1H15c0.5,0,1,0.4,1,1v0C16,2.6,15.6,3,15,3z"/>
<path class="st0" d="M15,7H1.9c-0.5,0-1-0.4-1-1v0c0-0.5,0.4-1,1-1H15c0.5,0,1,0.4,1,1v0C16,6.6,15.6,7,15,7z"/>
<path class="st0" d="M15,15H1c-0.5,0-1-0.4-1-1v0c0-0.5,0.4-1,1-1h14c0.5,0,1,0.4,1,1v0C16,14.6,15.6,15,15,15z"/>
<path class="st0" d="M15,11H3c-0.5,0-1-0.4-1-1v0c0-0.5,0.4-1,1-1h12c0.5,0,1,0.4,1,1v0C16,10.6,15.6,11,15,11z"/>
<path class="st0" d="M-9.2,10.8h-13.7c-0.6,0-1.1-0.5-1.1-1.1V9.6c0-0.6,0.5-1.1,1.1-1.1h13.7c0.6,0,1.1,0.5,1.1,1.1v0.1
	C-8.1,10.3-8.6,10.8-9.2,10.8z"/>
<path class="st0" d="M-9.2,7.3h-13.7c-0.6,0-1.1-0.5-1.1-1.1V6.2c0-0.6,0.5-1.1,1.1-1.1h13.7c0.6,0,1.1,0.5,1.1,1.1v0.1
	C-8.1,6.8-8.6,7.3-9.2,7.3z"/>
<path class="st0" d="M-9.2,3.9h-13.7c-0.6,0-1.1-0.5-1.1-1.1V2.8c0-0.6,0.5-1.1,1.1-1.1h13.7c0.6,0,1.1,0.5,1.1,1.1v0.1
	C-8.1,3.4-8.6,3.9-9.2,3.9z"/>
<path class="st0" d="M-13.4,14.2h-9.5c-0.6,0-1.1-0.5-1.1-1.1V13c0-0.6,0.5-1.1,1.1-1.1h9.5c0.6,0,1.1,0.5,1.1,1.1v0.1
	C-12.3,13.7-12.8,14.2-13.4,14.2z"/>
</svg>
`,
    },
    {
        'i-text-center': `
<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="16px" height="16px" viewBox="0 0 16 16" style="enable-background:new 0 0 16 16;" xml:space="preserve">
<path class="st0" d="M13.3,3H2.7c-0.5,0-1-0.4-1-1v0c0-0.5,0.4-1,1-1h10.6c0.5,0,1,0.4,1,1v0C14.3,2.6,13.8,3,13.3,3z"/>
<path class="st0" d="M14.6,7H1.4c-0.5,0-1-0.4-1-1v0c0-0.5,0.4-1,1-1h13.1c0.5,0,1,0.4,1,1v0C15.6,6.6,15.1,7,14.6,7z"/>
<path class="st0" d="M15,15H1c-0.5,0-1-0.4-1-1v0c0-0.5,0.4-1,1-1h14c0.5,0,1,0.4,1,1v0C16,14.6,15.6,15,15,15z"/>
<path class="st0" d="M14,11H2c-0.5,0-1-0.4-1-1v0c0-0.5,0.4-1,1-1h12c0.5,0,1,0.4,1,1v0C15,10.6,14.6,11,14,11z"/>
<path class="st0" d="M-9.2,10.8h-13.7c-0.6,0-1.1-0.5-1.1-1.1V9.6c0-0.6,0.5-1.1,1.1-1.1h13.7c0.6,0,1.1,0.5,1.1,1.1v0.1
	C-8.1,10.3-8.6,10.8-9.2,10.8z"/>
<path class="st0" d="M-9.2,7.3h-13.7c-0.6,0-1.1-0.5-1.1-1.1V6.2c0-0.6,0.5-1.1,1.1-1.1h13.7c0.6,0,1.1,0.5,1.1,1.1v0.1
	C-8.1,6.8-8.6,7.3-9.2,7.3z"/>
<path class="st0" d="M-9.2,3.9h-13.7c-0.6,0-1.1-0.5-1.1-1.1V2.8c0-0.6,0.5-1.1,1.1-1.1h13.7c0.6,0,1.1,0.5,1.1,1.1v0.1
	C-8.1,3.4-8.6,3.9-9.2,3.9z"/>
<path class="st0" d="M-13.4,14.2h-9.5c-0.6,0-1.1-0.5-1.1-1.1V13c0-0.6,0.5-1.1,1.1-1.1h9.5c0.6,0,1.1,0.5,1.1,1.1v0.1
	C-12.3,13.7-12.8,14.2-13.4,14.2z"/>
</svg>
`,
    },

    {
        'i-view-list': `
     <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
width="16px" height="16px" viewBox="0 0 16 16" style="enable-background:new 0 0 16 16;" xml:space="preserve">
<path class="st0" d="M15,4H5C4.4,4,4,3.6,4,3l0,0c0-0.5,0.4-1,1-1h10c0.5,0,1,0.4,1,1l0,0C16,3.6,15.6,4,15,4z"/>
    <path class="st0" d="M15,9.5H5c-0.5,0-1-0.4-1-1l0,0c0-0.5,0.4-1,1-1h10c0.5,0,1,0.4,1,1l0,0C16,9.1,15.6,9.5,15,9.5z"/>
    <path class="st0" d="M15,15H5c-0.5,0-1-0.4-1-1l0,0c0-0.5,0.4-1,1-1h10c0.5,0,1,0.4,1,1l0,0C16,14.6,15.6,15,15,15z"/>
    <circle class="st0" cx="1.8" cy="3" r="1"/>
    <circle class="st0" cx="1.8" cy="8.5" r="1"/>
    <circle class="st0" cx="1.8" cy="14" r="1"/>
    </svg>
`,
    },
    {
        'i-view-list-order': `
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="16px" height="16px" viewBox="0 0 16 16" style="enable-background:new 0 0 16 16;" xml:space="preserve">
<path class="st0" d="M15,4H5C4.4,4,4,3.6,4,3l0,0c0-0.5,0.4-1,1-1h10c0.5,0,1,0.4,1,1l0,0C16,3.6,15.6,4,15,4z"/>
<path class="st0" d="M15,9.5H5c-0.5,0-1-0.4-1-1l0,0c0-0.5,0.4-1,1-1h10c0.5,0,1,0.4,1,1l0,0C16,9.1,15.6,9.5,15,9.5z"/>
<path class="st0" d="M15,15H5c-0.5,0-1-0.4-1-1l0,0c0-0.5,0.4-1,1-1h10c0.5,0,1,0.4,1,1l0,0C16,14.6,15.6,15,15,15z"/>
<g class="st1">
	<path class="st0" d="M2.7,5H1.9V1.9C1.6,2.2,1.2,2.4,0.8,2.5V1.8C1,1.7,1.3,1.6,1.5,1.4C1.8,1.2,1.9,0.9,2,0.7h0.7V5z"/>
</g>
<g class="st1">
	<path class="st0" d="M2.9,9.4V10H0.5c0-0.2,0.1-0.5,0.2-0.7s0.4-0.5,0.8-0.9C1.8,8.2,2,8,2,7.9c0.1-0.1,0.1-0.3,0.1-0.4
		c0-0.2,0-0.3-0.1-0.4C2,7,1.9,7,1.7,7C1.6,7,1.5,7,1.4,7.1S1.2,7.3,1.2,7.5L0.5,7.5c0-0.4,0.2-0.6,0.4-0.8c0.2-0.2,0.5-0.2,0.8-0.2
		c0.4,0,0.6,0.1,0.8,0.3c0.2,0.2,0.3,0.4,0.3,0.7c0,0.2,0,0.3-0.1,0.5C2.7,8,2.6,8.2,2.5,8.3C2.4,8.4,2.3,8.6,2.1,8.8
		C1.8,9,1.7,9.1,1.6,9.2C1.6,9.2,1.5,9.3,1.5,9.4H2.9z"/>
</g>
<g class="st1">
	<path class="st0" d="M0.5,15.1L1.2,15c0,0.2,0.1,0.3,0.2,0.4c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.3-0.1,0.4-0.2
		c0.1-0.1,0.1-0.3,0.1-0.4c0-0.2,0-0.3-0.1-0.4s-0.2-0.2-0.3-0.2c-0.1,0-0.2,0-0.3,0.1l0.1-0.6c0.2,0,0.3,0,0.4-0.1
		C2,13.6,2,13.5,2,13.4c0-0.1,0-0.2-0.1-0.3C1.9,13,1.8,13,1.7,13c-0.1,0-0.2,0-0.3,0.1c-0.1,0.1-0.1,0.2-0.2,0.4l-0.6-0.1
		c0-0.2,0.1-0.4,0.2-0.5c0.1-0.1,0.2-0.2,0.4-0.3c0.2-0.1,0.3-0.1,0.5-0.1c0.3,0,0.6,0.1,0.8,0.3c0.2,0.2,0.3,0.4,0.3,0.6
		c0,0.3-0.2,0.6-0.5,0.8c0.2,0,0.4,0.1,0.5,0.3c0.1,0.2,0.2,0.3,0.2,0.6c0,0.3-0.1,0.6-0.3,0.8c-0.2,0.2-0.5,0.3-0.9,0.3
		c-0.3,0-0.6-0.1-0.8-0.3S0.6,15.4,0.5,15.1z"/>
</g>
</svg>
`,
    },
    {
        'i-plus': `
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z"/></svg>
`,
    },
    {
        'i-livestream': `
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="16px" height="16px" viewBox="0 0 19 19" enable-background="new 0 0 16 16" xml:space="preserve">
<g>
	<g>
		<path d="M17.793,4.733c-1.273-2.206-3.33-3.783-5.789-4.442C9.544-0.368,6.975-0.029,4.77,1.244
			C2.565,2.517,0.988,4.573,0.329,7.032c-0.66,2.459-0.321,5.028,0.952,7.235c1.273,2.203,3.329,3.781,5.789,4.441
			c0.821,0.221,1.655,0.328,2.482,0.328c1.652,0,3.282-0.434,4.752-1.281c2.205-1.273,3.783-3.33,4.442-5.787
			C19.404,9.507,19.065,6.938,17.793,4.733z M17.786,11.711c-0.591,2.203-2.004,4.045-3.979,5.186
			c-1.975,1.141-4.277,1.443-6.48,0.854c-2.203-0.59-4.045-2.004-5.186-3.98C1,11.795,0.697,9.493,1.288,7.29
			c0.59-2.204,2.003-4.045,3.979-5.187c1.316-0.759,2.776-1.147,4.256-1.147c0.743,0,1.489,0.097,2.224,0.295
			c2.202,0.59,4.045,2.003,5.185,3.978C18.074,7.205,18.377,9.507,17.786,11.711z"/>
	</g>
</g>
<g>
	<g>
		<path d="M14.11,8.601L7.982,5.062c-0.326-0.188-0.714-0.188-1.04,0c-0.325,0.187-0.52,0.523-0.52,0.899v7.076
			c0,0.377,0.194,0.713,0.52,0.902c0.163,0.094,0.341,0.139,0.519,0.139c0.179,0,0.357-0.047,0.52-0.139l6.129-3.541
			c0.325-0.186,0.52-0.523,0.52-0.899C14.63,9.125,14.436,8.788,14.11,8.601z M13.614,9.54l-6.129,3.539
			c-0.007,0.006-0.023,0.014-0.046,0c-0.023-0.014-0.023-0.029-0.023-0.041V5.961c0-0.008,0-0.026,0.023-0.04
			c0.009-0.005,0.017-0.007,0.023-0.007c0.01,0,0.018,0.004,0.022,0.007l6.129,3.54c0.008,0.003,0.023,0.012,0.023,0.039
			C13.638,9.527,13.622,9.535,13.614,9.54z"/>
	</g>
</g>
<g>
	<g>
		<path d="M15.195,4.905c-1.166-1.505-2.917-2.517-4.805-2.774C10.122,2.094,9.875,2.282,9.838,2.55
			c-0.037,0.268,0.15,0.515,0.419,0.551c1.636,0.224,3.153,1.1,4.163,2.404c0.096,0.125,0.241,0.19,0.388,0.19
			c0.104,0,0.21-0.034,0.299-0.102C15.321,5.427,15.361,5.119,15.195,4.905z"/>
	</g>
</g>
</svg>
        `,
    },
]

listCustomIcons.forEach(svg => {
    ClarityIcons.add(svg)
})
